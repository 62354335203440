<template>
  <div class="main_content">
    <div class="title_box">
      <span></span>
      <h5>任务管理</h5>
    </div>
    <div class="line"></div>
    <div class="operate_box">
      <div class="search_box">
        <div class="input_box">
          <span>打卡类型：</span>
          <el-select v-model="searchForm.type" clearable placeholder="请选择" style="width: 150px">
            <el-option :value="item.value" v-for="item in typeList" :label="item.label" :key="item.value" />
          </el-select>
        </div>
        <div class="input_box">
          <span>打卡方式：</span>
          <el-select v-model="searchForm.submitType" clearable placeholder="请选择" style="width: 150px">
            <el-option :value="item.value" v-for="item in subTypeList" :label="item.label" :key="item.value" />
          </el-select>
        </div>
        <div class="input_box">
          <span style="width: 70px;">创建时间：</span>
          <el-date-picker v-model="searchForm.stime" clearable type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间" style="width: 200px" :picker-options="beginPickerOptions" @focus="focus" />
          <span>—</span>
          <el-date-picker v-model="searchForm.etime" clearable type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间" style="width: 200px" :picker-options="endPickerOptions" @focus="focus" />
        </div>
        <div class="input_box">
          <span>状态：</span>
          <el-select v-model="searchForm.status" clearable placeholder="请选择" style="width: 150px">
            <el-option :value="item.value" v-for="item in statusList" :label="item.label" :key="item.value" />
          </el-select>
        </div>
        <div class="input_box"><el-button type="primary" class="el-icon-search" @click="searchTable"></el-button></div>
        <div class="input_box"> <el-button type="primary" icon="el-icon-plus" @click="addTask">新增</el-button></div>
        <!-- <div class="input_box"><el-button type="warning" icon="el-icon-download" @click="uploadExcel">导出</el-button></div> -->
      </div>
    </div>
    <div class="tbale_box">
      <el-table :data="tableData" border style="width: 100%" height="100%">
        <el-table-column prop="id" label="ID" width="100" />
        <el-table-column prop="type" label="类型">
          <template slot-scope="scope">
            <span>{{ getTypeName(scope.row.type) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="submitType" label="打卡方式">
          <template slot-scope="scope">
            <span>{{ getSubTypeName(scope.row.submitType) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">未发布</span>
            <span v-if="scope.row.status == 1">已发布</span>
            <span v-if="scope.row.status == 2">已停用</span>
          </template>
        </el-table-column>
        <el-table-column prop="begin" label="开始时间" />
        <el-table-column prop="end" label="结束时间" />
        <el-table-column prop="createdDatetime" label="创建时间" />
        <el-table-column width="200" label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" v-if="scope.row.status == 0 || scope.row.status == 1" content="编辑"
              placement="top">
              <el-button @click="updateTask(scope.row)" type="primary" icon="el-icon-edit" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="发布" placement="top" v-if="scope.row.status == 0">
              <el-button type="primary" icon="el-icon-s-promotion" circle @click="taskPubilsh(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="停用" placement="top" v-if="scope.row.status == 1">
              <el-button type="danger" circle icon="el-icon-video-pause" @click="pasueTask(scope.row)"></el-button>
            </el-tooltip>
            <!--<el-tooltip class="item" effect="dark" content="推送" placement="top" v-if="scope.row.status==1">-->
            <!--<el-button type="warning" circle icon='el-icon-message' @click="push(scope.row)"></el-button>-->
            <!--</el-tooltip>-->
            <el-tooltip class="item" effect="dark" v-if="scope.row.status == 0" content="删除" placement="top">
              <el-button type="danger" circle icon='el-icon-delete' @click="delTask(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="复制任务模板" placement="top">
              <el-button type="warning" icon="el-icon-copy-document" circle
                @click="copyTemplaye(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageBox.pageNum"
      :page-sizes="[10, 20, 50, 100, 200, 300, 400]" :page-size="pageBox.pageSize"
      layout="total, sizes, prev, pager, next, jumper" :total="pageBox.total" />
    <!--添加任务-->
    <el-dialog :visible.sync="editDialog" width="60%" :close-on-click-modal="false" :close-on-press-escape="false"
      class="set_dialog_style" @close="closed">
      <div slot="title" class="dialog_title">
        <span></span>{{ operateBox.title }}
      </div>
      <edit-from :operateBox="operateBox" @closed="closed" :key="timer"></edit-from>
    </el-dialog>
    <!--推送-->
    <el-dialog :visible.sync="pushDialog" width="30%" :close-on-click-modal="false" :close-on-press-escape="false"
      class="set_dialog_style">
      <div slot="title" class="dialog_title">
        <span></span>{{ operateBox.title }}
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* 设备告警 */
import EditFrom from './components/edit-from'
import Push from './components/push'
import api from "@/api/index"
import { submitTypeList, taskTypeList, statusList } from '@/utils/baseData'
import moment from 'moment'
export default {
  name: 'group-manage',
  components: { EditFrom, Push },
  data() {
    return {
      beginPickerOptions: {
        disabledDate: (time) => {
          if (this.searchForm.etime) {
            const endTime = new Date(this.searchForm.etime);
            return time.getTime() >= endTime.getTime()
          } else {
            return false
          }
        },
      },
      endPickerOptions: {
        disabledDate: (time) => {
          if (this.searchForm.stime) {
            const beginTime = new Date(this.searchForm.stime);
            return time.getTime() <= beginTime.getTime();
          } else {
            return false
          }
        },
      },
      tableData: [],
      editDialog: false,
      operateBox: {
        title: '添加任务',
        operate: 'add',
        id: null
      },
      CheckDialog: false,
      pushDialog: false,
      searchForm: {
        submitType: '',
        type: '',
        stime: null,
        etime: null,
        communityIds: null
      },
      pageBox: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      subTypeList: submitTypeList,
      typeList: taskTypeList,
      statusList: statusList,
      timer: null,
      isCopeyId: null
    }
  },
  created() {
    this.fetchUserPower()
  },
  mounted() {

  },
  methods: {

    getTypeName(type) {
      let typeName = '';
      switch (type) {
        case 0:
          typeName = '膳食打卡'
          break;
        case 1:
          typeName = '运动打卡'
          break;
        case 2:
          typeName = '血压监测打卡'
          break;
        case 3:
          typeName = '用药打卡'
          break;
        case 5:
          typeName = '考试打卡'
          break;
        case 6:
          typeName = '分享打卡'
          break;
        case 7:
          typeName = '监测打卡'
          break;
        case 8:
          typeName = '综合打卡'
          break;
      }
      return typeName
    },
    getSubTypeName(type) {
      const result = this.subTypeList.find(item => item.value === type);
      return result ? result.label : "未知类型";
      // let typeName = '';
      // switch (type) {
      //   case 0:
      //     typeName = '文字+图片上次传'
      //     break
      //   case 1:
      //     typeName = '问答'
      //     break
      //   case 2:
      //     typeName = '视频打卡'
      //     break
      //   case 3:
      //     typeName = '文字打卡'
      //     break
      //   case 4:
      //     typeName = '图片打卡'
      //     break
      // }
      // return typeName
    },
    //根据UserID回显权限res,拿到医生权限管理的社区
    async fetchUserPower() {
      try {
        let res = await api.getUserPower(this.$store.state.userId)
        if (res.code == 200) {
          if (this.$store.state.role !== 'admin') {
            this.searchForm.communityIds = res.data.comId
          }
          this.getTaskes()
        }
      } catch (e) {

      }
    },
    async getTaskes() {
      try {
        let data = JSON.parse(JSON.stringify(this.searchForm))
        console.log(this.searchForm);
        data.stime = data.stime ? data.stime.replace(/[-\s:]/g, '') : null
        data.etime = data.etime ? data.etime.replace(/[-\s:]/g, '') : null
        let res = await api.getTaskList(data, this.pageBox)
        console.log("resresres", res);
        if (res.code == 200) {
          this.tableData = res.rows
          this.pageBox.total = res.total
        } else {
          this.$message.error(res.msg)
        }
      } catch (e) {

      }
    },
    /* 一页显示多少条切换 */
    handleSizeChange(val) {
      this.pageBox.pageSize = val
      this.getTaskes()
    },
    /* 分页切换 */
    handleCurrentChange(val) {
      this.pageBox.pageNum = val
      this.getTaskes()
    },
    // 新增样例
    addTask() {
      if (this.isCopeyId == null) {
        this.operateBox = {
          title: '添加任务',
          operate: 'add',
          id: null
        }
      } else {
        this.operateBox = {
          title: '添加任务',
          operate: 'add',
          id: this.isCopeyId
        }
      }
      this.timer = new Date().getTime()
      this.editDialog = true
    },
    updateTask(row) {
      this.operateBox = {
        title: '编辑任务(为避免影响现有积分和排名，只能修改“未发布”的任务)',
        operate: 'edit',
        id: row.id
      }
      this.timer = new Date().getTime()
      this.editDialog = true
    },
    editTask() {

    },
    //推送
    pushTask() {
      this.operateBox = {
        title: '推送设置',
        operate: 'push',
        id: null
      }
      this.pushDialog = true
    },
    //根据条件查询
    searchTable() {
      this.getTaskes()
    },

    //发布打卡任务
    taskPubilsh(row) {
      this.$confirm('确定发布该数据吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.changeTaskStatus([row.id], 'release')
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消发布'
        });
      });
    },
    //停用打卡任务
    async pasueTask(row) {
      this.$confirm('确定停用该条打卡数据吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.changeTaskStatus([row.id], 'disable')
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消停用'
        });
      });
    },
    //删除打卡任务
    async delTask(row) {
      this.$confirm('确定删除该条打卡数据吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.changeTaskStatus([row.id], 'remove')
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //打卡任务状态变更,发布、停用、删除
    async changeTaskStatus(ids, methodsType) {
      try {
        let res = await api.taskStatusChange(ids, methodsType)
        if (res.code == 200) {
          this.$message.success('操作成功！')
          this.getTaskes()
        } else {
          this.$message.warning(res.msg)
        }
      } catch (e) {
        this.$message.warning(e.msg)
      }
    },
    closed() {
      this.getTaskes()
      this.editDialog = false
      this.CheckDialog = false
      this.pushDialog = false
      this.isCopeyId = null
    },
    //复制任务模板
    copyTemplaye(row) {
      this.isCopeyId = row.id
      this.$message.success('复制模板成功，请尽快进行模板添加')
    },

    //导出打卡任务
    async uploadExcel() {
      try {
        let res = await api.taskRecordExport(this.searchForm)
        if (res.code == 200) {
          console.log(res)
        }
      } catch (e) {

      }
    },
    // 隐藏此刻按钮
    focus() {
      this.$nextTick(() => {
        document
          .getElementsByClassName('el-button--text')[0]
          .setAttribute('style', 'display:none')
      })
    },
  },
  watch: {}
}
</script>

<style scoped lang="less">
.el-pagination {
  text-align: center;
}


.input_box {
  >span {
    font-size: 14px;
  }

  .el-input {
    width: 70%;
  }

  .el-select {
    width: 70%;
  }
}

/*页面特殊样式*/
.one_tag,
.two_tag,
.three_tag {
  padding: 5px 12px;
  border-bottom-right-radius: 24px;
  border-top-left-radius: 24px;
  color: #fff;
}

.one_tag {
  background: #c60c00;
  background: -webkit-linear-gradient(to bottom, #eb1610, #c66334);
  background: linear-gradient(to bottom, #eb1600, #c65f00);
}

.two_tag {
  background: #eb6601;
  background: -webkit-linear-gradient(to bottom, #eb6601, #c6aa07);
  background: linear-gradient(to bottom, #eb6601, #c6aa07)
}

.three_tag {
  background: #ebd007;
  background: -webkit-linear-gradient(to bottom, #ebd007, #bec606);
  background: linear-gradient(to bottom, #ebd007, #bec606)
}
</style>
