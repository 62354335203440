<template>
  <div class="form_box">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <div class="first_box">
        <el-divider content-position="left">第一步:基本信息</el-divider>
        <el-row>
          <el-col :span="12">
            <el-form-item label="打卡类型" prop="type">
              <el-select v-model="ruleForm.type" :disabled="isDisabled">
                <el-option v-for="item in taskTypes" :label="item.label" :value="item.value" :key="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="打卡方式" prop="submitType" :span="12">
              <el-select v-model="ruleForm.submitType" @change="changeSubType" :disabled="isDisabled">
                <el-option v-for="item in submitTypes" :label="item.label" :value="item.value" :key="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="exam_title_box">
          <el-divider content-position="left">题目设置</el-divider>
          <el-button class="add_title_btn" @click="addTitle" :disabled="isDisabled">新增题目</el-button>
          <div v-if="curTaskType == 1">
            <div class="exam_title_list" v-for="(item, index) in questionBox" :key="index"
              style="border: 1px solid #f5f5f5;margin: 0 10px 10px 10px">
              <div style="display: flex;width: 100%">
                <div style="flex: 4">
                  <el-form-item label="题干" prop>
                    <el-input v-model="item.title" :disabled="isDisabled" />
                  </el-form-item>
                </div>
                <div style="flex: 2">
                  <el-form-item label="类型" prop>
                    <el-select v-model.number="item.answerType" :disabled="isDisabled">
                      <el-option label="单选" :value="1" />
                      <el-option label="多选" :value="2" />
                    </el-select>
                  </el-form-item>
                </div>
                <div style="padding-left: 10px;flex: 2">
                  <el-button type="danger" icon="el-icon-delete" @click="deleteAnswer(index)"
                    :disabled="isDisabled">删除题目</el-button>
                  <el-button type="primary" icon="el-icon-plus" @click="addAnswer(item)"
                    :disabled="isDisabled">添加选项</el-button>
                </div>
              </div>
              <div class="answer_box">
                <div v-for="(citem, cindex) in item.answers" :key="cindex" style="display: flex">
                  <div style="flex: 4">
                    <el-form-item label="选项" prop>
                      <el-input v-model="citem.title" :disabled="isDisabled"></el-input>
                    </el-form-item>
                  </div>
                  <div style="flex: 2">
                    <el-form-item label="分值" prop>
                      <el-input v-model.number="citem.score" :disabled="isDisabled"></el-input>
                    </el-form-item>
                  </div>
                  <div style="padding-left: 10px;flex: 2">
                    <el-button type="danger" icon="el-icon-delete" @click="deleteOption(item, cindex)"
                      :disabled="isDisabled">删除选项</el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="curTaskType == 4">
            <div class="exam_title_list" v-for="(item, index) in questionBox" :key="index"
              style="border: 1px solid #f5f5f5;margin: 0 10px 10px 10px">
              <div style="display: flex;width: 100%">
                <div style="flex: 4">
                  <el-form-item label="题干" prop>
                    <el-input v-model="item.title" :disabled="isDisabled"></el-input>
                  </el-form-item>
                </div>
                <div style="flex: 2">
                  <el-form-item label="类型" prop>
                    <el-select v-model.number="item.answerType" :disabled="isDisabled">
                      <el-option label="单选" :value="1" />
                      <el-option label="多选" :value="2" />
                    </el-select>
                  </el-form-item>
                </div>
                <div style="padding-left: 10px;flex: 2">
                  <el-button type="danger" icon="el-icon-delete" @click="deleteAnswer(index)"
                    :disabled="isDisabled">删除题目</el-button>
                  <el-button type="primary" icon="el-icon-plus" @click="addAnswer(item)"
                    :disabled="isDisabled">添加选项</el-button>
                </div>
              </div>
              <div class="answer_box">
                <div v-for="(citem, cindex) in item.answers" :key="cindex"
                  style="display: flex;margin-bottom: 5px;align-items: center">
                  <div style="flex: 4;text-align: center">
                    <el-form-item label="选项" prop>
                      <el-upload class="uploadUrl" :accept="acceptArr" :show-file-list="false" :http-request="uploadImg"
                        :before-upload="beforeAvatarUpload">
                        <img v-if="citem.title" :src="citem.title" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon" @click="getCurItem(index, cindex)"></i>
                      </el-upload>
                    </el-form-item>
                  </div>
                  <div style="flex: 2">
                    <el-form-item label="分值" prop style="margin-bottom: 0">
                      <el-input v-model.number="citem.score" :disabled="isDisabled"></el-input>
                    </el-form-item>
                  </div>
                  <div style="padding-left: 10px;flex: 2">
                    <el-button type="danger" icon="el-icon-delete" @click="deleteOption(item, cindex)"
                      :disabled="isDisabled">删除选项</el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="curTaskType != 1 && curTaskType != null && curTaskType != 4">
            <el-row class="exam_title_list" v-for="(item, index) in examTitleList" :key="index">
              <el-col :span="13">
                <el-form-item label="题干" prop>
                  <el-input v-model="item.title" :disabled="isDisabled"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="分值" prop>
                  <el-input v-model.number="item.score" :disabled="isDisabled"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6" style="padding-left: 10px">
                <el-button type="danger" icon="el-icon-delete" @click="deleteTtile(index)"
                  :disabled="isDisabled">删除题目</el-button>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div class="first_box">
        <el-divider content-position="left">第二步:打卡时间配置</el-divider>
        <div style="display: flex">
          <el-form-item label="开始时间" prop="begin">
            <el-date-picker v-model="ruleForm.begin" type="date" :disabled="isDisabled"
              :picker-options="beginPickerOptions" placeholder="选择日期时间" value-format="yyyy-MM-dd" />
          </el-form-item>
          <!-- <el-row>
          <el-col :span="12">
            <el-form-item label="持续时长" prop="">
              <el-input v-model="ruleForm.duration"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="持续单位" prop="">
              <el-select v-model="ruleForm.unit" @change="selectUnit">
                <el-option v-for="item in unitBox" :label="item.label" :value="item.value" :key="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          </el-row>-->
          <el-form-item label="结束时间" prop="end">
            <el-date-picker v-model="ruleForm.end" type="date" :disabled="isDisabled" :picker-options="endPickerOptions"
              placeholder="选择日期时间" value-format="yyyy-MM-dd" />
          </el-form-item>
        </div>
      </div>
      <div class="first_box">
        <el-divider content-position="left">第三步:积分规则配置</el-divider>
        <div class="person_rules">
          <el-divider content-position="left">个人积分规则配置</el-divider>
          <div style="display: flex">
            <el-form-item label="目标打卡频率" prop="expFreq">
              <el-input v-model="ruleForm.expFreq" :disabled="isDisabled"></el-input>
            </el-form-item>
            <span style="line-height: 32px">次/周</span>
            <el-form-item label="分值" prop="expFreqScore">
              <el-input v-model="ruleForm.expFreqScore" :disabled="isDisabled"></el-input>
            </el-form-item>
          </div>
        </div>
      </div>
      <div class="first_box">
        <el-divider content-position="left">第四步:推送配置</el-divider>
        <div style="padding: 0 20px;display: flex;align-items: center">
          <p class="tips_box" v-if="operateBox.operate != 'add'">
            原推送小组：
            <span v-if="!this.ruleForm.status">(原推送小组发布后显示)</span>
            <span v-for="item in defaultGroup" :key="item.id">{{ item }},</span>
          </p>
          <el-input v-model="search" size="mini" clearable style="width:240px" placeholder="小组名称，仅筛选当前页面小组" />
        </div>
        <el-table v-loading="loading" :data="tables" border row-key="id" ref="multipleTable"
          @selection-change="handleSelectionChange" class="el_table_box">
          <el-table-column type="selection" :reserve-selection="true" :selectable="selectable"
            width="55"></el-table-column>
          <el-table-column prop="id" label="ID" width="100"></el-table-column>
          <el-table-column prop="name" label="小组名称"></el-table-column>
          <el-table-column prop="num" label="当前人数"></el-table-column>
        </el-table>
        <el-pagination v-if="!search" @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="pageBox.pageNum" :page-sizes="[10, 20, 50, 100, 200, 300, 400]" :page-size="pageBox.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="pageBox.total" />
        <!-- <new-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size='10'
          layout="total,sizes, prev, pager, next" :total=pageBox.total>
        </new-pagination>-->
      </div>
      <div class="sub_btn">
        <el-button type="primary" @click="addTask" :disabled="btnDisable">添加</el-button>
        <el-button @click="colsed">取消</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import api from '@/api/index'
import OSS from 'ali-oss'
import { taskTypeList, submitTypeList, taskUnit } from '@/utils/baseData'
import moment from 'moment'
export default {
  props: {
    operateBox: {
      type: Object,
      required: true
    },
    isCopeyId: {
      type: String,
      required: false
    }
  },
  name: 'edit-from',
  data() {
    return {
      beginPickerOptions: {
        disabledDate: (time) => {
          if (this.ruleForm.end) {
            const endTime = new Date(this.ruleForm.end)
            return (
              time.getTime() >= endTime.getTime() ||
              time.getTime() < new Date().getTime() - 24 * 60 * 60 * 1000
            )
          } else {
            // 手动选择可选择今天
            return time.getTime() < new Date().getTime() - 24 * 60 * 60 * 1000
          }
        }
      },
      endPickerOptions: {
        disabledDate: (time) => {
          if (this.ruleForm.begin) {
            const beginTime = new Date(this.ruleForm.begin)
            return time.getTime() <= beginTime.getTime()
          } else {
            return time.getTime() < Date.now()
          }
        }
      },
      communityIds: null,
      ruleForm: {
        type: '',
        begin: '',
        end: '',
        submitType: '',
        duration: '',
        unit: '',
        expFreq: '',
        expFreqScore: ''
      },
      searchForm: {
        communityIds: null
      },
      rules: {
        type: [{ required: true, message: '必填项不能为空', trigger: 'blur' }],
        submitType: [
          { required: true, message: '必填项不能为空', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '必填项不能为空', trigger: 'blur' }
        ],
        begin: [{ required: true, message: '必填项不能为空', trigger: 'blur' }],
        end: [{ required: true, message: '必填项不能为空', trigger: 'blur' }],
        expFreq: [
          { required: true, message: '只能输入数字' },
          {
            type: 'number',
            pattern: '/^[1-9]d{0,5}$/',
            message: '请输入整数，并且注意空格',
            trigger: 'blur',
            transform: (value) => Number(value)
          },
          {
            validator: (rule, value, callback) => {
              if ((value && value.toString().length > 3) || value < 0) {
                callback(new Error('不可超过3位数,不可小于0'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        expFreqScore: [
          { required: true, message: '只能输入数字' },
          {
            type: 'number',
            pattern: '/^[1-9]d{0,5}$/',
            message: '请输入整数，并且注意空格',
            trigger: 'blur',
            transform: (value) => Number(value)
          },
          {
            validator: (rule, value, callback) => {
              if ((value && value.toString().length > 3) || value < 0) {
                callback(new Error('不可超过3位数,不可小于0'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ]
      },
      examTitleList: [],
      questionBox: [],
      tableData: [],
      taskTypes: taskTypeList,
      submitTypes: submitTypeList,
      unitBox: taskUnit,
      curTaskType: null,
      pageBox: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      groupIds: [],
      selectIds: [],
      isDisabled: false,
      ossData: '',
      index1: null,
      index2: null,
      defaultGroup: [],
      acceptArr: '.jpg,.png,.JPG,.JPGE,.jpge',
      btnDisable: false,
      search: '',
      loading: false
    }
  },
  computed: {
    tables() {
      const search = this.search.toLowerCase() // 将搜索词转换为小写以进行不区分大小写的比较
      if (search) {
        return this.tableData.filter((dataNews) => {
          // 检查对象的"name"属性是否包含搜索词
          return String(dataNews.name).toLowerCase().indexOf(search) > -1
        })
      }
      return this.tableData
    }
  },
  created() {
    this.fetchUserPower()
    if (this.operateBox.id != null) {
      this.getTaskInfo(this.operateBox.id)
    }
    this.ossSet()
  },
  methods: {
    // 根据UserID回显权限res,拿到医生权限管理的社区
    async fetchUserPower() {
      try {
        const res = await api.getUserPower(this.$store.state.userId)
        if (res.code == 200) {
          // if (this.$store.state.role !== 'admin') {
          this.searchForm.communityIds = res.data.comId
          // }
          this.getGroupList(this.searchForm)
        }
      } catch (e) { }
    },
    // 根据UserID回显权限res,拿到医生权限管理的社区
    // async fetchUserPower() {
    //   try {
    //     let res = await api.getUserPower(this.$store.state.userId)
    //     if (res.code == 200) {
    //       if (this.$store.state.role !== 'admin') {
    //         this.communityIds = res.data.comId
    //       }
    //     }
    //     this.getGroupList(this.searchForm, this.pageBox)
    //   } catch (e) {

    //   }
    // },

    // 阿里云图片上传授权
    async ossSet() {
      try {
        const res = await api.setOss()
        if (res.httpStatus == 'OK') {
          this.ossData = res.data.entity
        }
      } catch (e) { }
    },
    beforeAvatarUpload(file) {
      let isLt2M = null
      if (this.curTaskType == 4) {
        isLt2M = file.size / 1024 / 1024 < 2
        if (!isLt2M) {
          this.$message.warning('上传头像图片大小不能超过 2MB!')
        }
        return isLt2M
      }
      // if(this.curTaskType==2){
      //   isLt2M = file.size / 1024 / 1024 < 100;
      //   if (!isLt2M) {
      //     this.$message.warning('上传头像图片大小不能超过 100MB!');
      //   }
      //   return isLt2M;
      // }
    },
    getCurItem(index, cindex) {
      this.index1 = index
      this.index2 = cindex
    },
    async uploadImg(option) {
      console.log(this.questionBox)
      const _this = this
      try {
        const client = new OSS({
          region: 'oss-cn-beijing',
          accessKeyId: _this.ossData.accessid,
          accessKeySecret: 'QBlrMUba9AWAvBzfdVoLT3vUMcW9hO',
          bucket: 'hit-dev',
          securityToken: _this.ossData.policy
        })
        console.log('c', client)
        const file = option.file
        const point = file.name.lastIndexOf('.')
        const suffix = file.name.substr(point)
        const fileName = file.name.substr(0, point)
        const date = moment().format('YYYYMMDDHHmm')
        const relativePath = this.ossData.dir
        // let fileNames = `${fileName}_${date}${suffix}`;
        client
          .put(relativePath + fileName, file, {})
          .then(({ res, url, name }) => {
            if (res && res.status == 200) {
              this.questionBox[this.index1].answers[this.index2].title = url
              this.$message({
                message: '上传成功',
                type: 'success'
              })
            }
          })
          .catch((err) => {
            this.$message({
              message: '上传失败11',
              type: 'error'
            })
          })
      } catch (error) {
        this.$message({
          message: '上传失败',
          type: 'error'
        })
      }
    },
    // 根据id获取打卡详情用于回显
    async getTaskInfo(id) {
      try {
        const res = await api.getTaskInfoById(id)
        if (res.code == 200) {
          this.ruleForm = res.data.taskInfo
          if (
            res.data.taskInfo.status == 1 &&
            this.operateBox.operate == 'edit'
          ) {
            this.isDisabled = true
          }
          if (
            res.data.groupIds != null &&
            this.operateBox.operate == 'edit' &&
            res.data.taskInfo.status == 1
          ) {
            this.reselectFun(this.tableData, res.data.groupIds)
            // 将 groupIds 转换为 Set
            const groupIdsSet = new Set(res.data.groupIds)
            // 遍历 tableData 数组
            this.tableData.forEach((citem) => {
              // 检查当前项的 id 是否在 groupIdsSet 中
              if (groupIdsSet.has(citem.id)) {
                // 如果存在，则禁用当前项并将其名称添加到 defaultGroup 数组中
                citem.disabled = true
                this.defaultGroup.push(citem.name)
              }
            })
            console.log('循环后', this.defaultGroup)
            this.selectIds = res.data.groupIds
          }
          this.curTaskType = res.data.taskInfo.submitType

          if (
            res.data.taskInfo.submitType == 1 ||
            res.data.taskInfo.submitType == 4
          ) {
            this.questionBox = res.data.topicInfo ? res.data.topicInfo : []
          } else {
            this.examTitleList = res.data.topicInfo ? res.data.topicInfo : []
          }
        }
      } catch (e) { }
    },
    selectable(row, index) {
      if (row.disabled == undefined && this.ruleForm.status == 1) {
        return true
      }
      if (this.operateBox.operate == 'add') {
        return true
      }
      if (this.ruleForm.status == 0) {
        return true
      }
    },
    // 复选
    reselectFun(dataArr, IDArr) {
      var _this = this
      _this.$nextTick(function () {
        dataArr.forEach((row) => {
          if (IDArr.includes(row.id)) {
            _this.$refs.multipleTable.toggleRowSelection(row, true)
          }
        })
      })
    },

    // 获取小组列表
    async getGroupList(data) {
      var _this = this
      this.loading = true
      try {
        const res = await api.getGroupListes(data, this.pageBox)
        console.log('res', res)
        if (res.code == 200) {
          res.rows.forEach((item) => {
            item.auth = JSON.parse(item.auth)
          })
          _this.tableData = res.rows
          console.log(' _this.tableData ', _this.tableData)
          _this.pageBox.total = res.total
          if (_this.operateBox.operate == 'edit') {
            _this.reselectFun(_this.tableData, _this.selectIds)
          }
        }
      } catch (e) {
        this.$message.error(e)
      } finally {
        this.loading = false
      }
    },
    /* 一页显示多少条切换 */
    handleSizeChange(val) {
      this.pageBox.pageSize = val
      this.getGroupList()
    },
    /* 分页切换 */
    handleCurrentChange(val) {
      this.pageBox.pageNum = val
      this.getGroupList()
    },

    // selectUnit(val) {
    //   if (this.ruleForm.begin == '' || this.ruleForm.duration == '') {
    //     this.$message.info('请设置开始时间及持续时常！')
    //     this.ruleForm.unit = ''
    //   } else {
    //     let begin = moment(this.ruleForm.begin).format('YYYY-MM-DD')
    //     let dayCount = val * Number(this.ruleForm.duration)
    //     let end = this.dateAddDays(begin, dayCount)
    //     this.ruleForm.end = end
    //   }
    // },
    changeSubType(val) {
      this.curTaskType = val
      this.examTitleList = []
      this.questionBox = []
      // if(val==4){
      //   this.acceptArr='.jpg,.png,.JPG,.JPGE,.jpge'
      // }
      // if(val==2){
      //   this.acceptArr='video/mp4,video/avi, video/wmv'
      // }
    },
    // 自动计算结束日期
    dateAddDays(dateStr, dayCount) {
      var tempDate = new Date(dateStr.replace(/-/g, '/')) // 把日期字符串转换成日期格式
      var resultDate = new Date((tempDate / 1000 + 86400 * dayCount) * 1000) // 增加n天后的日期
      var resultDateStr =
        resultDate.getFullYear() +
        '-' +
        (resultDate.getMonth() + 1) +
        '-' +
        resultDate.getDate() // 将日期转化为字符串格式
      return resultDateStr
    },

    addTitle() {
      if (this.curTaskType == null) {
        this.$message.warning('请选择打卡方式')
        return false
      } else if (this.curTaskType == 1 || this.curTaskType == 4) {
        this.questionBox.push({
          title: '',
          answerType: null,
          answers: [{ title: '', score: null }]
        })
      } else {
        this.examTitleList.push({ title: '', score: '' })
        console.log(this.examTitleList)
      }
    },
    deleteTtile(val) {
      this.examTitleList.splice(val, 1)
    },
    deleteAnswer(val) {
      console.log(val)
      this.questionBox.splice(val, 1)
    },
    addAnswer(item) {
      item.answers.push({ title: '', score: null })
    },
    deleteOption(item, index) {
      item.answers.splice(index, 1)
    },
    // 获取小组id
    handleSelectionChange(val) {
      var groupIds = []
      val.forEach((item) => {
        groupIds.push(item.id)
      })
      this.groupIds = groupIds
      console.log(this.groupIds)
    },

    // 新增任务
    async addTask() {
      if (this.groupIds.length <= 0) {
        return this.$message.warning('推送人员未选择')
      }
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.btnDisable = true
          if (this.operateBox.operate == 'add') {
            const dataArr = {
              groupIds: this.groupIds,
              taskInfo: {
                stime: this.ruleForm.begin + ' 00:00:00',
                etime: this.ruleForm.end + ' 23:59:59',
                expFreq: Number(this.ruleForm.expFreq),
                expFreqScore: Number(this.ruleForm.expFreqScore),
                submitType: Number(this.ruleForm.submitType),
                type: Number(this.ruleForm.type),
                groupRuleIds: '',
                regionCode: String(this.searchForm.communityIds[0]),
                topicIds: ''
              },

              topicInfo: null
            }
            console.log('dataArr', dataArr)
            if (this.curTaskType == 1 || this.curTaskType == 4) {
              dataArr.topicInfo = this.questionBox
            } else {
              dataArr.topicInfo = this.examTitleList
            }
            if (!dataArr.topicInfo.length) {
              this.$message.warning('请先设置题目后再提交')
              this.btnDisable = false
              return
            }
            if (!this.validateArr(dataArr.topicInfo, this.curTaskType)) {
              this.btnDisable = false
              return
            }
            const res = await api.taskAdd(dataArr)
            console.log(res)
            if (res.code == 200) {
              this.$message.success('新增打卡任务成功！')
              this.$emit('closed')
            } else {
              this.$message.warning('新增打卡任务失败，请仔细检查数据重新提交')
            }
          } else {
            const dataArr = {
              groupIds: this.groupIds,
              taskInfo: {
                id: this.ruleForm.id,
                stime: this.ruleForm.begin.indexOf(' ') !== -1 ? this.ruleForm.begin : this.ruleForm.begin + ' 00:00:00',
                etime: this.ruleForm.end.indexOf(' ') !== -1 ? this.ruleForm.end : this.ruleForm.end + ' 23:59:59',
                expFreq: Number(this.ruleForm.expFreq),
                expFreqScore: Number(this.ruleForm.expFreqScore),
                submitType: Number(this.ruleForm.submitType),
                type: Number(this.ruleForm.type),
                groupRuleIds: '',
                regionCode: String(this.searchForm.communityIds[0])
              },
              topicInfo: null
            }
            if (this.curTaskType == 1 || this.curTaskType == 4) {
              dataArr.topicInfo = this.questionBox
            } else {
              dataArr.topicInfo = this.examTitleList
            }
            if (!dataArr.topicInfo.length) {
              this.$message.warning('请先设置题目后再提交')
              this.btnDisable = false
              return
            }
            if (!this.validateArr(dataArr.topicInfo, this.curTaskType)) {
              this.btnDisable = false
              return
            }

            const res = await api.taskEdit(dataArr)
            if (res.code == 200) {
              this.$message.success('修改打卡任务成功！')
              this.$emit('closed')
            } else {
              this.$message.warning(res.msg)
            }
          }
        }
      })
    },

    // 关闭弹窗
    colsed() {
      this.$emit('closed')
    },
    //校验方法
    validateArr(arr, type) {
      if (type === 1) {
        for (let i = 0; i < arr.length; i++) {
          const item = arr[i];

          // 校验title属性
          if (!item.title) {
            this.$message.warning('请填写题干');
            return false;
          }

          // 校验answerType属性
          if (!item.answerType) {
            this.$message.warning('请选择类型');
            return false;
          }

          // 校验answers属性
          if (!item.answers || item.answers.length === 0) {
            this.$message.warning('请添加选项');
            return false;
          }

          // 根据answerType进行不同的校验
          if (item.answerType == 1 && item.answers.length < 2) {
            this.$message.warning('单选题至少需要两个选项');
            return false;
          }

          if (item.answerType == 2 && item.answers.length < 3) {
            this.$message.warning('多选题至少需要三个选项');
            return false;
          }

          // 校验answers数组中每个选项的title和score属性
          for (let j = 0; j < item.answers.length; j++) {
            const answer = item.answers[j];

            if (!answer.title) {
              this.$message.warning('请填写选项标题');
              return false;
            }

            if (answer.score === '' || answer.score === undefined || answer.score === null) {
              this.$message.warning('请填写选项分值');
              return false;
            }
            // 校验score属性是否为数字
            if (isNaN(answer.score)) {
              this.$message.warning('分值必须为数字');
              return false;
            }

            // 校验score属性是否为正整数
            const score = parseInt(answer.score);
            if (score < 0 || score > 100 || !Number.isInteger(score)) {
              this.$message.warning('分值必须为0-100之间的整数');
              return false;
            }
          }
        }

        // 所有校验通过
        return true;
      } else if (type === 0 || type === 3) {
        // 遍历arr数组
        for (let i = 0; i < arr.length; i++) {
          const item = arr[i];

          // 校验title属性
          if (!item.title) {
            this.$message.warning('请填写题干');
            return false;
          }

          // 校验score属性
          if (item.score == '' || item.score == undefined || item.score == null) {
            this.$message.warning('请填写分值');
            return false;
          }
          // 校验score属性是否为数字
          if (isNaN(item.score)) {
            this.$message.warning('分值必须为数字');
            return false;
          }

          // 校验score属性是否为正整数
          const score = parseInt(item.score);
          if (score < 0 || score > 100 || !Number.isInteger(score)) {
            this.$message.warning('分值必须为0-100之间的整数');
            return false;
          }
        }

        // 所有校验通过
        return true;
      } else if (type === 4) {
        for (let i = 0; i < arr.length; i++) {
          const item = arr[i];

          // 校验title属性
          if (!item.title) {
            this.$message.warning('请填写题干');
            return false;
          }

          // 校验answerType属性
          if (!item.answerType) {
            this.$message.warning('请选择类型');
            return false;
          }

          // 校验answers属性
          if (!item.answers || item.answers.length === 0) {
            this.$message.warning('请添加选项');
            return false;
          }

          // 根据answerType进行不同的校验
          if (item.answerType == 1 && item.answers.length < 2) {
            this.$message.warning('单选题至少需要两个选项');
            return false;
          }

          if (item.answerType == 2 && item.answers.length < 3) {
            this.$message.warning('多选题至少需要三个选项');
            return false;
          }

          // 校验answers数组中每个选项的title和score属性
          for (let j = 0; j < item.answers.length; j++) {
            const answer = item.answers[j];

            if (!answer.title) {
              this.$message.warning('请上传选项图片');
              return false;
            }

            console.log(answer.score);
            if (answer.score === '' || answer.score === undefined || answer.score === null) {
              console.log('111', answer.score);
              this.$message.warning('请填写选项分值');
              return false;
            }

            // 校验score属性是否为数字
            if (isNaN(answer.score)) {
              this.$message.warning('分值必须为数字');
              return false;
            }

            // 校验score属性是否为正整数
            const score = parseInt(answer.score);
            if (score < 0 || score > 100 || !Number.isInteger(score)) {
              this.$message.warning('分值必须为0-100之间的整数');
              return false;
            }
          }
        }

        // 所有校验通过
        return true;
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped lang="less">
.el-pagination {
  text-align: center;
}

.form_box {
  overflow-y: auto;
  height: 620px;
  overflow-x: hidden;

  .first_box {
    border-left: 1px solid #dcdfe6;
    border-right: 1px solid #dcdfe6;
    border-bottom: 1px solid #dcdfe6;

    .exam_title_box {
      overflow: auto;
      position: relative;

      .add_title_btn {
        position: absolute;
        top: 10px;
        right: 10px;
      }

      .exam_title_list {
        padding: 0 10px;
      }
    }

    .person_rules {
      border-left: 1px solid #dcdfe6;
      border-right: 1px solid #dcdfe6;
      border-bottom: 1px solid #dcdfe6;
      margin: 20px;
    }

    .tips_box {
      color: #ff8115;
      font-size: 12px;
      padding: 0 20px;
      flex: 1;
    }
  }
}

.el-input,
.el-select,
.el-picker {
  width: 100%;
  padding-right: 10px !important;
}

.el_table_box {
  margin: 10px;
  width: calc(100% - 20px);
}

.tips_box {
  line-height: 36px;
}

.sub_btn {
  text-align: left;
  margin-top: 10px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dashed #d9d9d9 !important;
}

.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
