<template>
  <div class="form_box">
    <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="打卡名称" prop="clockName">
        <el-input v-model="ruleForm.clockName"></el-input>
      </el-form-item>
      <el-form-item label="打卡时间" prop="clockTime">
        <el-input v-model="ruleForm.clockTime" placeholder="格式 18:00:00~19:00:00"></el-input>
      </el-form-item>
      <el-form-item label="任务说明" prop="taskSpecification">
        <el-input v-model="ruleForm.taskSpecification"></el-input>
      </el-form-item>
      <el-form-item label="打卡次数" prop="clockFrequency">
        <el-input v-model="ruleForm.clockFrequency"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">确认</el-button>
        <el-button @click="cancelFrom('ruleForm')">取消</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>

<script>
import debounce from 'lodash/debounce';
export default {
  props: {
    operateBox: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ruleForm: {
        clockName: '',
        clockTime: '',
        taskSpecification: '',
        clockFrequency: '',
      },
    };
  },
  methods: {
    submitForm: debounce(function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }, 500),
    cancelFrom() {

    }
  }
}
</script>

<style scoped></style>
